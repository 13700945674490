.menu {
    display: flex;
    justify-content: center;
    align-content: center;
}

.menu-contents {
    text-align: center;
    margin: auto;
}

.noarrow-menu{
    display: flex;
}

.youtubeEmpty {
    display: flex;
    justify-content: center;
}

.youtubeList {
    display:absolute;
    padding: 0;
    list-style-type: none;
}

.youtubeBorder {
    background-color: rgb(249, 249, 249);
    display: flex;
    height: 10%;
    width: 100%;
    margin-top: 1px;
    border-top: 1px solid rgb(230, 230, 230);
}

.youtubeRankingOut {
    width: 50px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.youtubeRankingIn {
    margin: auto;
}

.youtubeImage {
    display: flex;
}

.youtubeView {
    display:inline;
    align-self: flex-end;
}

.youtubeTitle {
    display: inline-block;
    width: 300px;
    margin-left: 5px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.iframe16To9 {
    width: 100%;
    height: 30vh;
    top: 0;
    left: 0;
    margin-bottom: 5px;
}

.youtubeTagBorder {
    background-color: rgb(249, 249, 249);
    display: flex;
    height: 10%;
    width: 100%;
    border-bottom: 1px solid rgb(230, 230, 230);
    margin: auto;
}
.youtubeTag{
    border: 1px solid rgb(203, 203, 203);
    cursor:pointer; 
    cursor:hand;
    background-color: rgb(204, 204, 204);
    color: black;
    padding: 5px;
    border-radius: 2em;
    margin-top: 2px;
    margin-left: 2px;
}