body {
    /* The image used */
    background-image: url("background.jpg");
  
    /* Full height */
    height: 100%;
  
    /* Center and scale the image nicely */
    overflow: auto;

    background-position: center 10%;
    background-repeat: no-repeat;
    background-size: cover;
}

#wrap {
    min-height: 100vh;
    position: relative;
    width: 100%; 
}

footer {
    width: 100%;
    height: 90px;
    bottom: 0px;
    position: absolute;
    border-top: 1px solid #868686;
    padding-top: 15px;
    color: #f9f7f7;
    font-size: 11px;
    background-color: #383838e2;
}

footer p {
    margin-top: 0; margin-bottom: 0;   
}

footer p span {
    display: inline-block;
    margin-left: 20px;
}
 
/* Split the screen in half */
.split {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
}

/* Control the left side */
.left {
    left: 0;
    background-color: #111;
}

/* Control the right side */
.right {
    right: 0;
    background-color: red;
}

/* If you want the content centered horizontally and vertically */
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}