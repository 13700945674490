.Wrapper {
    background-color: aliceblue;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Container {
    height: 550px;
    width: 500px;
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: center;
    margin-bottom: 10px;
    border: 2px solid gray; /* 테두리 두께 및 색 */
    border-radius: 20px; /* 꼭짓점 둥근 정도 */
    & > * { /* 각 항목별 간격(?) → 잘 모르겠음 */
        :not(:last-child) {
            margin-bottom: 25px;
        }
    }
}

.PostContainer {
    height: 500px;
    width: 300px;
    padding: 25px 16px;
}

.PhotoContainer {
    height: 200px;
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-left: 10px;
}

.Photo {
    background-image: url("../nulluser_downsize.jpg");
    border: 3px solid gray;
    height: 105px;
    width: 105px;
    border-radius: 100%;
    object-fit: cover;
}

.Label {
    font-size: 16px; /* 글자 크기 */
    font-weight: 500;
}

.Button {
    padding: 8px 16px; /* 잘 모르겠음 */
    font-size: 16px;
    border: 2px solid gray;
    border-radius: 8px;
    cursor: pointer; /* 잘 모르겠음 */
}